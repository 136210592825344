import {
    createVacation,
    fetchVacationsForEmployee,
    updateVacation,
} from "@rpforms/shared/build/actions/vacations";
import { fetchEmployeeById } from "@rpforms/shared/build/actions/employees";
import { SmallDangerButton } from "@rpforms/shared/build/components/Button";
import { PrimaryButton } from "@rpforms/shared/build/components/layout/Button";
import moment, { Moment } from "moment";
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { DateRangePicker } from "react-dates";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import styled from "styled-components";
import { RootState } from "../../reducers";
import VacationValidator, { calculateHolidays, countWeekendDays } from "./vacationUtils";

const AddVacationWrapper = styled.div`
    padding: 20px 20px;
    background: #fafafa;
    width: 84%;
    .DateRangePicker {
        margin-top: 0 !important;
        margin-bottom: 20px;
    }

    label {
        margin-top: 20px;
    }
`;

interface AddVacationProps {
    employeeId: any;
    freeVacationDays: number;
    residualVacationDays: number;
    setFreeVacationDays: (arg0: number) => void;
    setResidualVacationDays: (arg0: number) => void;
    vacation?: any;
    setShowEditId?: (arg0: any) => void;
    holidayArr: any[];
    vacationDays: number;
    nextYearFreeVacationDays: number;
    setNextYearFreeVacationDays: (arg0: number) => void;
}

export const AddVacation = ({
    employeeId,
    freeVacationDays,
    residualVacationDays,
    setFreeVacationDays,
    setResidualVacationDays,
    vacation = null,
    setShowEditId = null,
    holidayArr,
    vacationDays,
    nextYearFreeVacationDays,
    setNextYearFreeVacationDays,
}: AddVacationProps) => {
    const [startDate, setStartDate] = useState(
        vacation ? moment(vacation.from, "YYYY-MM-DD") : null
    );
    const [endDate, setEndDate] = useState(vacation ? moment(vacation.to, "YYYY-MM-DD") : null);
    const [focusedInput, setFocusedInput] = useState(null);
    const [calculatedVacationDays, setCalculatedVacationDays] = useState(0);
    const [needsPermission, setNeedsPermission] = useState(vacation ? !vacation.permitted : false);
    const [extraHoliday, setExtraHoliday] = useState(vacation ? vacation.extra_holiday : false);
    const [residualVacation, setResidualVacation] = useState(
        vacation ? vacation.residual_vacation : false
    );
    const [isSingleDay, setIsSingleDay] = useState(false);
    const [isHalfDay, setIsHalfDay] = useState(vacation ? vacation.days === 0.5 : false);

    const [holidays, setHolidays] = useState(0);
    const dispatch = useDispatch();
    const vacations = useSelector<RootState, any>((state) => state.vacations.list);

    useEffect(() => {
        if (startDate && endDate) {
            setHolidays(calculateHolidays(moment(startDate), moment(endDate), holidayArr));
            calculateDays();
        }
    }, [extraHoliday, startDate, endDate, isHalfDay]);

    const calculateDays = () => {
        const startDay = moment(startDate).startOf("day");
        const endDay = moment(endDate).startOf("day");

        const isSingleDay = startDay.isSame(endDay, "day");
        setIsSingleDay(isSingleDay);

        const holidays = calculateHolidays(startDay, endDay, holidayArr);
        const daysWithWeekends = moment.duration(endDay.diff(startDay)).asDays() + 1;
        const weekendDays = countWeekendDays(startDay, daysWithWeekends);

        const totalWeekdays = Math.ceil(daysWithWeekends - weekendDays);

        if (holidays > totalWeekdays) {
            toast.error("Die Anzahl der Feiertage überschreitet den Zeitraum", {
                autoClose: 2000,
            });
            return;
        }

        let calculatedDays = totalWeekdays - holidays - (extraHoliday ? 1 : 0);
        if (isSingleDay && isHalfDay) {
            calculatedDays = 0.5;
        }

        setCalculatedVacationDays(calculatedDays);
    };

    const editVacation = async () => {
        const params = {
            ...vacation,
            from: moment(startDate).format("DD-MM-YYYY"),
            to: moment(endDate).format("DD-MM-YYYY"),
            days: calculatedVacationDays,
            permitted: !needsPermission,
            extra_holiday: extraHoliday,
            residual_vacation: residualVacation,
        };
        const oldDays = vacation.days;
        await dispatch(updateVacation(params));
        setShowEditId(0);
        const isNextYear = moment(endDate).isAfter(moment().endOf("year"));
        const newlyAddedDays = calculatedVacationDays - oldDays;

        const handleEditVacationForNextYear = () => {
            if (residualVacation) {
                setFreeVacationDays(freeVacationDays - newlyAddedDays);
                return;
            }
            setNextYearFreeVacationDays(nextYearFreeVacationDays - newlyAddedDays);
        };

        const handleEditVacationForThisYear = () => {
            if (residualVacation) {
                setResidualVacationDays(residualVacationDays - newlyAddedDays);
                return;
            }
            setFreeVacationDays(freeVacationDays - newlyAddedDays);
        };

        if (isNextYear) {
            handleEditVacationForNextYear();
            return;
        }
        handleEditVacationForThisYear();
    };

    const createNewVacation = async () => {
        const params = {
            from: moment(startDate).format("DD-MM-YYYY"),
            to: moment(endDate).format("DD-MM-YYYY"),
            days: calculatedVacationDays,
            permitted: !needsPermission,
            employee_id: parseInt(employeeId, 10),
            extra_holiday: extraHoliday,
            residual_vacation: residualVacation,
        };

        const isNextYear = moment(endDate).isAfter(moment().endOf("year"));

        const parsedFreeVacationDays = freeVacationDays;
        const newFreeDaysForThisYear = parsedFreeVacationDays - calculatedVacationDays;

        await dispatch(createVacation(params));

        const createNewVacationForNextYear = () => {
            if (residualVacation) {
                setFreeVacationDays(newFreeDaysForThisYear);
                return;
            }
            setNextYearFreeVacationDays(nextYearFreeVacationDays - calculatedVacationDays);
        };

        const createNewVacationForThisYear = () => {
            if (residualVacation) {
                setResidualVacationDays(residualVacationDays - calculatedVacationDays);
                return;
            }
            setFreeVacationDays(newFreeDaysForThisYear);
        };

        if (isNextYear) {
            createNewVacationForNextYear();
            return;
        }
        createNewVacationForThisYear();
    };

    const onCreateClick = async () => {
        const validator = new VacationValidator({
            startDate,
            endDate,
            vacation,
            vacations,
            residualVacation,
            residualVacationDays,
            freeVacationDays,
            vacationDays,
            calculatedVacationDays,
            nextYearFreeVacationDays,
        });

        const isThisYear = moment(endDate).isBefore(moment().endOf("year"));
        const isNextYear = moment(endDate).isAfter(moment().endOf("year"));

        if (!validator.validate()) return;
        if (isThisYear && !validator.validateThisYear()) return;
        if (isNextYear && !validator.validateNextYear()) return;

        try {
            if (vacation) {
                await editVacation();
                toast.success("Urlaub bearbeitet", { autoClose: 2000 });
            } else {
                await createNewVacation();
                toast.success("Urlaub erstellt", { autoClose: 2000 });
            }
            await dispatch(fetchEmployeeById(employeeId));
        } catch (e) {
            console.log(e);
            toast.error("Es ist etwas schiefgelaufen, bitte versuchen Sie es später erneut.", {
                autoClose: 2000,
            });
        }
    };

    if (holidayArr.length === 0) {
        return <></>;
    }

    return (
        <AddVacationWrapper>
            <Row>
                <Col xs={11}>
                    {vacation ? (
                        <p>Hier können Sie den ausgewählten Urlaub bearbeiten</p>
                    ) : (
                        <p>Hier können Sie einen neuen Urlaub für diesen Mitarbeiter hinzufügen.</p>
                    )}
                </Col>
                <Col>
                    {setShowEditId && (
                        <SmallDangerButton
                            style={{ position: "relative", top: "5px", right: "5px" }}
                            onClick={() => setShowEditId(0)}
                        >
                            x
                        </SmallDangerButton>
                    )}
                </Col>
            </Row>
            <Row>
                <Col sm={5}>
                    <label>Zeitraum (Wochenenden und Feiertage werden nicht mitgezählt)</label>
                </Col>
                <Col sm={5}>
                    <label>Anzahl der Feiertage innerhalb dieses Zeitraums</label>
                </Col>
            </Row>
            <Row style={{ alignItems: "center" }}>
                <Col sm={5}>
                    <DateRangePicker
                        startDate={startDate || null} // momentPropTypes.momentObj or null,
                        startDateId="scheduled_from" // PropTypes.string.isRequired,
                        endDate={endDate || null} // momentPropTypes.momentObj or null,
                        endDateId="scheduled_to" // PropTypes.string.isRequired,
                        onDatesChange={({ startDate, endDate }) => {
                            setStartDate(startDate);
                            setEndDate(endDate);
                        }} // PropTypes.func.isRequired,
                        minimumNights={0}
                        displayFormat={"DD.MM.YYYY"}
                        focusedInput={focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
                        onFocusChange={(xFocusedInput) => setFocusedInput(xFocusedInput)} // PropTypes.func.isRequired,
                    />
                </Col>
                <Col sm={5}>
                    <input style={{ width: "100%" }} value={holidays} disabled />
                </Col>
            </Row>
            <Row>
                <Col xs={10}>
                    <label>Berechnete Anzahl der Urlaubstage</label>
                    <input
                        disabled
                        style={{ width: "100%", backgroundColor: "#F2F2F2" }}
                        value={calculatedVacationDays}
                    />
                </Col>
            </Row>
            {isSingleDay && (
                <>
                    <label style={{ marginRight: "5px" }}>Halber Tag</label>
                    <input
                        type="checkbox"
                        onChange={(e) => setIsHalfDay(e.target.checked)}
                        checked={isHalfDay}
                    />
                    <br />
                </>
            )}
            <label style={{ marginRight: "5px" }}>Erfordert Freigabe</label>
            <input
                type="checkbox"
                onChange={(e) => setNeedsPermission(e.target.checked)}
                checked={needsPermission}
            />
            <br />
            <label style={{ marginRight: "5px" }}>Mit Brückentag</label>
            <input
                type="checkbox"
                onChange={(e) => setExtraHoliday(e.target.checked)}
                checked={extraHoliday}
            />
            <br />
            <label style={{ marginRight: "5px" }}>
                {moment(endDate) > moment().endOf("year") ? (
                    <>
                        Urlaub aus aktuellem Jahr ({freeVacationDays}{" "}
                        {freeVacationDays === 1 ? "Tag" : "Tage"}) verwenden?
                    </>
                ) : (
                    <>
                        Resturlaub ({residualVacationDays}{" "}
                        {residualVacationDays === 1 ? "Tag" : "Tage"}) verwenden
                    </>
                )}
            </label>
            <input
                type="checkbox"
                onChange={(e) => setResidualVacation(e.target.checked)}
                checked={residualVacation}
            />

            <div style={{ marginTop: "10px" }}>
                <PrimaryButton onClick={onCreateClick}>
                    {vacation ? "Urlaub bearbeiten" : "Urlaub eintragen"}
                </PrimaryButton>
            </div>
        </AddVacationWrapper>
    );
};

export default AddVacation;
