import {
    fetchDeviceToken,
    FORMS_SYNC_SELECTION,
    syncForms,
    toSelectOptions,
} from "@rpforms/shared";
import { PrimaryButton } from "@rpforms/shared/build/components/Button";
import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import styled from "styled-components";
import { RootState } from "../../reducers";

const StyledModalTitle = styled(Modal.Title)`
    display: flex;
    justify-content: space-between;
    align-items: center;
    line-height: 24px;
    font-size: 16px !important;
    width: 100%;
    // align svg icons with fonts
    svg {
        position: relative;
        top: -2px;
    }
`;

const StyledModalBody = styled(Modal.Body)`
    padding: 20px;
`;

const FormSection = styled.div`
    margin-bottom: 24px;
`;

const EnvironmentSelect = styled(Select)`
    margin-bottom: 12px;
`;

const LoadingOverlay = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 40px 20px;

    img {
        max-width: 200px;
        margin-bottom: 16px;
    }
`;

const FormList = styled.div`
    background: #f8f9fa;
    padding: 12px;
    border-radius: 4px;
    margin: 12px 0;
`;

const FormCount = styled.small`
    margin-left: 8px;
`;

const ButtonRow = styled.div`
    display: flex;
    justify-content: space-between;
    gap: 8px;
`;

const ErrorMessage = styled.div`
    color: red;
    margin-bottom: 12px;
`;

enum ENVIRONMENT_NAMES {
    LIVE = "Live",
    STAGING = "Staging",
    CUSTOM = "Benutzerdefiniert",
}

const TARGET_ENVIRONMENTS = [
    {
        name: ENVIRONMENT_NAMES.LIVE,
        url: "https://api.rpforms.kega.network",
        deviceId: 3,
    },
    {
        name: ENVIRONMENT_NAMES.STAGING,
        url: "https://api.staging.rpforms.kega.network",
        deviceId: 1,
    },
    {
        name: ENVIRONMENT_NAMES.CUSTOM,
        url: "http://localhost:3009",
        deviceId: null,
        deviceToken: null,
    },
];

interface Form {
    id: string | number;
    name: string;
}

export const FormsSyncModal = ({ show, setVisibility }) => {
    const forms = useSelector<RootState, any>((state) => state.forms);
    const dispatch = useDispatch();
    const [endpoint, setEndpoint] = useState(TARGET_ENVIRONMENTS[TARGET_ENVIRONMENTS.length - 1]);
    const [success, setSuccess] = useState(false);
    const company = useSelector<any, any>((state) => state.company);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState("");
    const [syncedForms, setSyncedForms] = useState<Form[]>(() =>
        forms.forms.filter((form: Form) => forms.syncForms.includes(form.id))
    );

    const onEnvironmentChange = ({ value, label }: { value: string; label: string }) => {
        setError("");
        setEndpoint(
            TARGET_ENVIRONMENTS.find((e) => e.url === value) ??
                TARGET_ENVIRONMENTS[TARGET_ENVIRONMENTS.length - 1]
        );
    };

    const handleSync = async () => {
        if (!endpoint.deviceId && endpoint.name !== ENVIRONMENT_NAMES.CUSTOM) {
            setError("Kein Device-ID für diese Umgebung konfiguriert");
            return;
        }

        if (endpoint.name === ENVIRONMENT_NAMES.CUSTOM) {
            if (!endpoint.deviceToken?.trim()) {
                setError("Bitte einen Device-Token eingeben");
                return;
            }
            if (!endpoint.url?.trim()) {
                setError("Bitte geben Sie eine gültige URL ein");
                return;
            }
        }

        setIsLoading(true);
        setError("");

        try {
            const deviceToken =
                endpoint.name === ENVIRONMENT_NAMES.CUSTOM
                    ? endpoint.deviceToken
                    : await dispatch(fetchDeviceToken(endpoint.deviceId));

            if (!deviceToken) {
                throw new Error("Kein Device-Token erhalten");
            }

            dispatch(
                syncForms(forms.syncForms, endpoint.url, deviceToken, (msg, statusCode) => {
                    if (statusCode === 200) {
                        setSuccess(true);
                    } else {
                        setError(
                            msg ||
                                "Fehler beim Synchronisieren. Bitte überprüfen Sie den Device-Token."
                        );
                    }
                })
            );
        } catch (error) {
            setError(`Fehler bei der Synchronisierung: ${error.message}`);
        } finally {
            setIsLoading(false);
        }
    };

    const close = () => {
        dispatch({ type: FORMS_SYNC_SELECTION, payload: false });
        setVisibility(false);
    };

    return (
        <Modal show={show} onHide={close} size={"lg"} backdrop="static" keyboard={false}>
            <Modal.Header closeButton>
                <StyledModalTitle>
                    <div>
                        Formulare synchronisieren
                        {syncedForms.length > 0 && (
                            <FormCount>
                                ({syncedForms.length}{" "}
                                {syncedForms.length === 1 ? "Formular" : "Formulare"})
                            </FormCount>
                        )}
                    </div>
                </StyledModalTitle>
            </Modal.Header>

            <StyledModalBody>
                {isLoading || forms.syncIsLoading ? (
                    <LoadingOverlay>
                        <img
                            src="https://media.giphy.com/media/UEU7BpGn2W9Xy/giphy.gif"
                            alt="Loading"
                        />
                        <p>Synchronisierung läuft...</p>
                    </LoadingOverlay>
                ) : success ? (
                    <FormSection>
                        <div className="alert alert-success">
                            <h4>Synchronisierung erfolgreich</h4>
                            <p>Die ausgewählten Formulare wurden erfolgreich synchronisiert:</p>
                            <FormList>{syncedForms.map((f) => f.name).join(", ")}</FormList>
                            <p>
                                <strong>Von:</strong> {company.name || "Aktuelle Umgebung"}
                                <br />
                                <strong>Nach:</strong> {endpoint.name}
                            </p>
                        </div>
                        <Button variant="success" onClick={close}>
                            Schließen
                        </Button>
                    </FormSection>
                ) : (
                    <>
                        <FormSection>
                            <h5>Ausgewählte Formulare</h5>
                            <FormList>{syncedForms.map((f) => f.name).join(", ")}</FormList>
                        </FormSection>

                        <FormSection>
                            <h5>Zielumgebung</h5>
                            <EnvironmentSelect
                                onChange={onEnvironmentChange}
                                options={toSelectOptions(TARGET_ENVIRONMENTS, "name", "url")}
                                placeholder="Umgebung auswählen..."
                                isSearchable={false}
                            />

                            {endpoint.name === ENVIRONMENT_NAMES.CUSTOM && (
                                <>
                                    <input
                                        type="text"
                                        placeholder="Endpunkt URL"
                                        value={endpoint.url}
                                        onChange={(e) =>
                                            setEndpoint({ ...endpoint, url: e.target.value })
                                        }
                                        className="form-control"
                                        style={{ marginBottom: "12px" }}
                                    />

                                    <h5>Device-Token</h5>
                                    <textarea
                                        value={endpoint.deviceToken}
                                        onChange={(e) =>
                                            setEndpoint({
                                                ...endpoint,
                                                deviceToken: e.target.value,
                                            })
                                        }
                                        className="form-control"
                                        placeholder="Device-Token hier einfügen..."
                                        rows={3}
                                    />
                                </>
                            )}
                        </FormSection>

                        {error && (
                            <ErrorMessage>
                                <i className="fa fa-exclamation-triangle"></i> {error}
                            </ErrorMessage>
                        )}

                        <ButtonRow>
                            <Button variant="secondary" onClick={close}>
                                Abbrechen
                            </Button>
                            <PrimaryButton
                                onClick={handleSync}
                                disabled={isLoading || forms.syncIsLoading}
                            >
                                {isLoading ? "Wird synchronisiert..." : "Synchronisieren"}
                            </PrimaryButton>
                        </ButtonRow>
                    </>
                )}
            </StyledModalBody>
        </Modal>
    );
};
