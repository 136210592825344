import { PrimaryButton } from "@rpforms/shared";
import { batchDelete } from "@rpforms/shared/build/actions/actions_helper";
import { fetchEmployeeById } from "@rpforms/shared/build/actions/employees";
import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import styled from "styled-components";
import {
    fetchVacationsForEmployee,
    deleteVacation,
    updateVacation,
} from "@rpforms/shared/build/actions/vacations";
import { RootState } from "../../reducers";
import moment from "moment";
import { Loader } from "@rpforms/shared/build/components/universal/Loader";
import {
    SmallSuccessButton,
    SmallDangerButton,
    SmallButton,
} from "@rpforms/shared/build/components/Button";
import AddVacation from "./AddVacation";

const VacationRow = styled.div`
    padding: 5px 0px;
    margin: 5px 0px;
`;

export const VacationList = ({
    employee,
    freeVacationDays,
    setFreeVacationDays,
    holidayArr,
    nextYearFreeVacationDays,
    setNextYearFreeVacationDays,
    residualVacationDays,
    setResidualVacationDays,
}) => {
    const [selectedIds, setSelectedIds] = useState([]);
    const [showEditId, setShowEditId] = useState(0);
    const vacations = employee.vacations;
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchVacationsForEmployee(employee));
    }, []);

    if (!vacations) return <Loader />;

    const deleteVacations = async () => {
        if (selectedIds.length === 0) return;
        try {
            await dispatch(
                batchDelete("vacations", selectedIds, () => {
                    dispatch(fetchVacationsForEmployee(employee));
                })
            );
            const days = vacations
                .filter(
                    (v) =>
                        selectedIds.includes(v.id) &&
                        v.to <= new Date(new Date().getFullYear(), 11, 31)
                )
                .reduce((acc, current) => acc + parseInt(current.days), 0);
            setFreeVacationDays(freeVacationDays + days);
            await dispatch(fetchEmployeeById(employee.id));
            setSelectedIds([]);
            toast.success("Urlaub wurde erfolgreich gelöscht.", { autoClose: 2000 });
        } catch (e) {
            console.log(e);
            toast.error("Es ist etwas schiefgelaufen. Bitte versuchen Sie es später erneut.", {
                autoClose: 2000,
            });
        }
    };

    const toggleId = (vac) => {
        const vacId = vac.target.value;
        const parsedId = parseInt(vacId, 10);
        if (!selectedIds.includes(parsedId)) {
            setSelectedIds(selectedIds.concat([parsedId]));
        } else {
            setSelectedIds(selectedIds.filter((a) => a !== parsedId));
        }
    };

    const grantPermission = async (id) => {
        const vacation = vacations.find((vac) => vac.id === id);
        try {
            await dispatch(updateVacation({ ...vacation, permitted: true }));
            await dispatch(fetchVacationsForEmployee(employee));
            toast.success("Der Urlaub wurde erfolgreich genehmigt", { autoClose: 2000 });
        } catch (e) {
            console.log(e);
        }
    };

    const denyPermission = async (id) => {
        const vacation = vacations.find((vac) => vac.id === id);
        try {
            await dispatch(deleteVacation(vacation));
            await dispatch(fetchVacationsForEmployee(employee));
            toast.success("Der Urlaub wurde erfolgreich abgelehnt", { autoClose: 2000 });
            const days = vacations.find((v) => v.id === id).days;
            setFreeVacationDays(freeVacationDays + days);
        } catch (e) {
            console.log(e);
        }
    };

    const editVacation = async (id) => {
        setShowEditId(id);
    };

    return (
        <>
            {vacations.map((vac) => {
                const id = vac.id;
                const from = moment(vac.from).format("DD.MM.YY");
                const to = moment(vac.to).format("DD.MM.YY");
                return (
                    <div key={id}>
                        <VacationRow>
                            <Form.Check type="checkbox" id={id}>
                                <Form.Check.Input
                                    checked={selectedIds.includes(id)}
                                    type="checkbox"
                                    value={id}
                                    onChange={toggleId}
                                />
                                <Form.Check.Label className={"mt-0"}>
                                    <span>
                                        {from} - {to} ({vac.days} Tage) | Freigegeben (
                                        {vac.permitted ? "✓" : "x"}){" "}
                                        {vac.residual_vacation
                                            ? ` | Resturlaub von ${moment(vac.from)
                                                  .subtract(1, "y")
                                                  .format("YYYY")}  (✓)`
                                            : ""}
                                        <SmallButton onClick={() => editVacation(id)}>
                                            Bearbeiten
                                        </SmallButton>
                                        {!vac.permitted && (
                                            <>
                                                <SmallSuccessButton
                                                    style={{ backgroundColor: "#0E0" }}
                                                    onClick={() => grantPermission(id)}
                                                >
                                                    Genehmigen
                                                </SmallSuccessButton>
                                                <SmallDangerButton
                                                    onClick={() => denyPermission(id)}
                                                >
                                                    Ablehnen
                                                </SmallDangerButton>
                                            </>
                                        )}
                                    </span>
                                </Form.Check.Label>
                            </Form.Check>
                        </VacationRow>
                        {showEditId === id && holidayArr && (
                            <AddVacation
                                setShowEditId={setShowEditId}
                                vacation={vac}
                                employeeId={employee.id}
                                freeVacationDays={freeVacationDays}
                                setFreeVacationDays={setFreeVacationDays}
                                holidayArr={holidayArr}
                                vacationDays={employee.vacation_days}
                                nextYearFreeVacationDays={nextYearFreeVacationDays}
                                setNextYearFreeVacationDays={setNextYearFreeVacationDays}
                                residualVacationDays={residualVacationDays}
                                setResidualVacationDays={setResidualVacationDays}
                            />
                        )}
                    </div>
                );
            })}
            <div className="row ml-1">
                <PrimaryButton onClick={deleteVacations}>
                    {selectedIds.length > 1 ? "Urlaube löschen" : "Urlaub löschen"}
                </PrimaryButton>
            </div>
        </>
    );
};
